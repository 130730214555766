import React, {useState} from 'react'
import styled from 'styled-components'
import Header from '../Header/Header'
import img from "../Assets/uniAbuja.jpeg"
import img2 from "../Assets/mat1.jpg"
import img3 from "../Assets/mat2.jpg"
import img4 from "../Assets/mat3.jpg"
import img5 from "../Assets/mat4.jpg"
import Footer from '../Footer/Footer'
import Pagination from '../Pagination/Pagination'
import axios from 'axios'
import Swal from 'sweetalert2'

interface User {
	coverImage: string;
	title: string;
}

const Gallery = () => {

    const [currentpage, setCurrentpage] = useState(1)
    const [postperpage, setPostperpage] = useState(28)
    const [postData, setPostData] = useState<User[]>([])

    const indexOfLastPost = currentpage * postperpage
    const indexOfFirstPost = indexOfLastPost - postperpage
    const currentPosts = postData.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = (pageNumber: number) => setCurrentpage(pageNumber)

    const ReadData = async () => {
      await axios.get("http://localhost: 2030/api/get")
        .then((res) => {
          setPostData(res.data.data)
          Swal.fire({
            icon: "success",
            title: "Image posted successfully",
            timer: 3000
          })
        })
    }

  return (
    <Container>
      <Wrapper>
        <Top>
          <Img src={img} />
          <Left>
            <Text>Hello Welcome to <span>Uni-Abuja</span> photo gallery with memories and events coming up</Text>
          </Left>
        </Top>
        {/* {currentPosts?.map((props) => ( */}
          <Down>
          <Imageholder>
            <Image>
              <Hold src={img2} />
            </Image>
            <Namehold>
              <Name>Okwoli Matthew</Name>
              <Stack>CEO BetaLife</Stack>
            </Namehold>
          </Imageholder>
          <Imageholder>
            <Image>
              <Hold src={img3} />
            </Image>
            <Namehold>
              <Name>Okwoli Matthew</Name>
              <Stack>CEO BetaLife</Stack>
            </Namehold>
          </Imageholder>
          <Imageholder>
            <Image>
              <Hold src={img4} />
            </Image>
            <Namehold>
              <Name>Okwoli Matthew</Name>
              <Stack>CEO BetaLife</Stack>
            </Namehold>
          </Imageholder>
          <Imageholder>
            <Image>
              <Hold src={img5} />
            </Image>
            <Namehold>
              <Name>Okwoli Matthew</Name>
              <Stack>CEO BetaLife</Stack>
            </Namehold>
          </Imageholder>
          <Pagi><Pagination postsPerPage={postperpage} totalPosts={postData.length} paginate={paginate}/></Pagi>
        </Down>

      </Wrapper>
    </Container>
  )
}

export default Gallery
const Pagi = styled.div`
	width: 100%;
	display: flex;
	height: 20px;
	background-color: red;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
`
const Stack = styled.div`
  
`
const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
`
const Namehold = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
`
const Hold = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Image = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  /* background-color: green; */
`
const Imageholder = styled.div`
  width: 250px;
  height: 400px;
  border-radius: 15px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: 17px;
`
const Down = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
const Text = styled.div`
  font-size: 18px;
  width: 480px;
  text-align: center;
  span{
    color: #219653;
    font-size: 20px;
  }
`
const Left = styled.div`
  display: flex;
`
const Img = styled.img`
  height: 150px;
`
const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  padding-top: 50px;
  padding-bottom: 90px;
`
const Wrapper = styled.div`
  width: 80%;
  height: 100%;
  /* background-color: red; */
  margin-top: 80px;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`