import React from 'react'
import Header from '../Header/Header'

const About = () => {
  return (
    <div>

    </div>
  )
}

export default About