import React from 'react'
import styled from 'styled-components'

const Visionhold = () => {
  return (
    <Container>
        <Wrapper>
            <Vision>
                <Top><h2>Our Vision</h2></Top>
                <P>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam ipsam obcaecati quidem? Quis eius repellat, dolores rerum recusandae quasi illo rem autem optio architecto. Repellat perspiciatis suscipit vel nihil saepe eius, vero, voluptatum odit accusantium blanditiis repellendus impedit asperiores veritatis possimus quo expedita maiores iure quia! Assumenda cumque totam ratione.</P>
            </Vision>
             <Vision>
                <Top><h2>Our Mission</h2></Top>
                <P>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam ipsam obcaecati quidem? Quis eius repellat, dolores rerum recusandae quasi illo rem autem optio architecto. Repellat perspiciatis suscipit vel nihil saepe eius, vero, voluptatum odit accusantium blanditiis repellendus impedit asperiores veritatis possimus quo expedita maiores iure quia! Assumenda cumque totam ratione.</P>
            </Vision>
        </Wrapper>
    </Container>
  )
}

export default Visionhold
const P = styled.div`
    width: 80%;
    margin-left: 80px;
`
const Top = styled.div`
    margin-left: 80px;
    h2{
        font-weight: 700;
        font-size: 43px;
        line-height: 2rem;
    }
`
const Vision = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
`

const Wrapper = styled.div`
    width: 80%;
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`