import React from 'react'
import Covered from '../Covered/Covered'
import Explore from '../Explore/Explore'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Hero from '../Hero/Hero'
import Partners from '../Partners/Partners'
import Vid from '../Videocomp/Vid'
import Visionhold from '../Visionhold/Visionhold'

const Homescreen = () => {
  return (
    <div>

        <Hero />
        <br />
        <br />
        <Explore />
        <br />
        <Visionhold />
        <Partners />
        <Vid />
        <Covered />
        <br />
        <br />
        <Footer />
    </div>
  )
}

export default Homescreen