import React, {useState} from 'react'
import styled from 'styled-components'
import uniAbuja from "../Assets/uniAbuja.jpeg"
import {FiMenu} from "react-icons/fi"
import { NavLink } from 'react-router-dom'

const Header = () => {

    const [show, setShow] = useState(false)

    const Toggle = () => {
        setShow(!show)
    }
  return (
    <Container>
        <Wrapper>
            <Logo>
                <Img src={uniAbuja} />
            </Logo>
            <Navbars>
                <NavLink to="/">
                    <Navs>Home</Navs>
                </NavLink>
                <NavLink to="/about">
                    <Navs>About us</Navs>
                </NavLink>
                <NavLink to="/gallery">
                    <Navs>Gallery</Navs>
                </NavLink>
                <NavLink to="/contact us">
                    <Navs>Contact us</Navs>
                </NavLink>
                <NavLink to="/journal">
                    <Navs>Journal</Navs>
                </NavLink>
                <Button>Apply</Button>
            </Navbars>
            <Icon onClick={Toggle}>
                <FiMenu />
            </Icon>
        </Wrapper>
        {show ? (
            <Card>
                <DropNav>Home</DropNav>
                <DropNav>About Us</DropNav>
                <DropNav>Gallery</DropNav>
                <DropNav>Contact us</DropNav>
                <Signin>Journal</Signin>
                <Create>Apply</Create>
            </Card>
        ) : null}
    </Container>
  )
}

export default Header
const Create = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.375rem;
    font-family: "Avenir", sans-serif;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    outline: none;
    height: 43px;
    /* border: 1px solid #219653; */
    background-color: #219653;
    cursor: pointer;
    width: 120px;
`
const Signin = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.375rem;
    font-family: "Avenir", sans-serif;
    color: #219653;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    outline: none;
    height: 43px;
    border: 1px solid #219653;
    /* background-color: #219653; */
    cursor: pointer;
    width: 120px;
`
const DropNav = styled.div`
    font-weight: 500;
    cursor: pointer;
    font-size: 23px;
    margin: 15px;
    color: #646464;
`
const Card = styled.div`
    width: 100%;
    height: 610px;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 80px;
    transition: ease-in-out 350ms;
    position: fixed;
    display: none;
    @media screen and (max-width: 874px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`
const Icon = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    display: none;
    cursor: pointer;
    @media screen and (max-width: 874px) {
        display: flex;
    }
`
const Button = styled.button`
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.375rem;
    font-family: "Avenir", sans-serif;
    color: white;
    border: none;
    outline: none;
    height: 40px;
    background-color: #219653;
    cursor: pointer;
    width: 100px;
`
const Navs = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: "Avenir", sans-serif;
    cursor: pointer;
    margin-right: 25px;
`
const Navbars = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    /* background-color: blue; */
    @media screen and (max-width: 874px) {
        display: none;
    }
`
const Img = styled.img`
    height: 65px;
`
const Logo = styled.div`
    width: 180px;
    height: 100%;
    display: flex;
    /* background-color: green; */
    align-items: center;
`
const Wrapper = styled.div`
    width: 90%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
`

const Container = styled.div`
    width: 100%;
    height: 80px;
    display: flex; 
    justify-content: center;
    position: fixed;
    /* position: relative; */
    transition: ease-in-out 350ms;
    background-color: #FAFAFA;
`